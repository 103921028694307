import React from 'react'

function Interest() {
  return (
    <div className='inter'>
        <h3>Interest</h3>
        <p>Food expert. Music scholar. Reader. Internet fanatic. Bacon buff. Entrepreneur. Travel geek. Pop culture ninja. Coffee fanatic.</p>
    </div>
  )
}

export default Interest